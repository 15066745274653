import React, { useContext, useEffect, useRef, useState } from 'react';
import ConsentListContext from './Context/ConsentContext';
import AuthApis from '../hepler/authApis.service';
import { toast } from 'react-toastify';
import RequestedConsents from './paymentConsent/RequestedConsents';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import LinkedAccountsSection from './Accounts/LinkedAccountsSection';
// import ConsentCard from './paymentConsent/ConsentCard';
import ConsentCardFull from './paymentConsent/ConsentCardFull/ConsentCardFull';
import linkedAccountsSectionStyles from './Accounts/LinkedAccountsSection.styles';
import DialogBox from './Dialog Box/Dialog Box';
import WhyShare from './Sections/WhyShare';
import DenyConsent from './Accounts/DenyConsent';
import { areAllArraysEmptyForConsents } from '../hepler/functions';
import FloatingButton from './Fab/FloatingButton';
import Drawer from './Drawer/Drawer';
import FiuList from './paymentConsent/FiuList';

function PaymentConsent(props) {
	const consentsContext = useContext(ConsentListContext);
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const consentsAll = consentsContext.consentList;
	const consentsAccepted = consentsContext.acceptedConsentsMultiConsent;
	const consentsDenied = consentsContext.deniedConsentsMultiConsent;
	const [state, setState] = useState({
		linkedAccounts: [],
		selectedAccounts: [],
		consentDetailsList: consentsContext?.consentList,
		isDisable: false,
		consentSelectedAccounts: [],
	});
	const [selectAll, setSelectAll] = useState(consentsAll.length===consentsContext?.selectedConsents.length);
  const [openDrawer, setOpenDrawer] = useState(false)
	// console.log('State: ', state);
	const authApis = new AuthApis();
	const SUBMITTING_CONSENT_COUNTER =
		consentsAll.length > 1 && global.institutionType !== 'LSP' ? 1000 : 0;

	//New
	const [selectedConsentsState, setSelectedConsentsState] = useState(
		consentsContext?.selectedConsents.map((a) => {
			return a?.ConsentHandle;
		})
	);
	const [deny, setDeny] = useState(false);
	const handleClose = (event, reason) => {
		if (reason && reason === 'backdropClick') {
			return;
		}
		setDeny(false);
	};

	const selectUnselectConsents = (consent) => {
		setSelectedConsentsState((prevConsents) => {
			if (prevConsents.includes(consent)) {
				const index = prevConsents.indexOf(consent);
				prevConsents.splice(index, 1);
			} else {
				prevConsents.push(consent);
			}
			consentsContext.updateSelectedConsentList(
				consentsAll.filter((x) => prevConsents.includes(x.ConsentHandle))
			);
      if (prevConsents.length < consentsAll.length) {
				setSelectAll(false);
			} else {
				setSelectAll(true);
			}

			return [...prevConsents];
		});
	};

	useEffect(() => {
		if (props?.location?.state?.data?.length) {
			const consentSelections = setConsentSelectedAccount(
				props?.location?.state?.data
			);
			setState({
				...state,
				linkedAccounts: props?.location?.state?.data,
				selectedAccounts: [...props?.location?.state?.data],
				consentSelectedAccounts: consentSelections,
			});
			props.updateLoader(false);
		} else {
			props.updateLoader(true);
			getLinkedAcc();
		}
	}, []);

	const setConsentSelectedAccount = (accounts) => {
		const res = {};
		for (const consent of consentsAll) {
			const filteredAccounts = accounts.filter((x) =>
				consent?.fiTypes.includes(x?.FIType)
			);
			res[consent?.ConsentHandle] = [...filteredAccounts];
		}
		return res;
	};

	const getLinkedAcc = () => {
		authApis.getLinkedAccounts().then((res) => {
			props.updateLoader(false);
			if (res?.status === 'SUCCESS') {
				setState({
					...state,
					linkedAccounts: res?.LinkedAccounts,
					selectedAccounts: [...res?.LinkedAccounts],
					consentDetailsList: consentsContext.consentList,
					consentSelectedAccounts: setConsentSelectedAccount(
						res?.LinkedAccounts
					),
				});
			} else {
				props.updateLoader(false);
				toast.error(res?.message);
				console.log(res?.message);
				if (res?.status === 'RECORD-NOT-FOUND') {
					props.history.push(
						`/home/?number=${
							global.mobileNumber
						}&isAddAccount=${true}&isNewUser=${true}`
					);
				} else {
					if (res?.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					} else if (res?.status === 'ERROR') {
						postMessage('session', 'ERROR');
					}
					setTimeout(() => {
						props.history.push({
							pathname: `/consent-status`,
							state: {
								statusCode: 403,
							},
						});
					}, 1000);
				}
			}
		});
	};

	const updateContext = (consentHandle, status) => {
		if (status === 'ACCEPT') {
			consentsAccepted.push(consentHandle);
			consentsContext.updateAcceptedConsentsMultiConsent(consentsAccepted);
		} else {
			consentsDenied.push(consentHandle);
			consentsContext.updateDeniedConsentsMultiConsent(consentsDenied);
		}
		consentsAll.push(consentsAll.shift());
		consentsContext.updateConsentList(consentsAll);
	};

	const consentApproveRequest = async (
		accounts,
		status,
		consentHandle,
		fiuID,
		isLast
	) => {
		setState({ ...state, isDisable: true });
		await authApis
			.consentApproveRequest(accounts, status, consentHandle, fiuID)
			.then((res) => {
				if (res && res?.status === 'SUCCESS') {
					const consentStatusText = status === 'ACCEPT' ? 'Consent approved' : 'Consent denied'
                
					if (isLast) {
						setTimeout(() => {
							updateContext(consentHandle, status);
							toast.success(
								consentStatusText
							);
							props.history.push({
								pathname: `/consent-status`,
								state: {
									status:consentStatusText,
									statusCode: status === 'ACCEPT' ? 200 : 201,
								},
							});
						}, SUBMITTING_CONSENT_COUNTER);
					} else {
						setTimeout(() => {
							updateContext(consentHandle, status);
							toast.success(
								consentStatusText
							);
							setState({ ...state, isDisable: false });
						}, SUBMITTING_CONSENT_COUNTER);
					}
				} else {
					toast.error(res?.message);
					console.log(res?.message);
					if (res?.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					} else if (res?.status === 'ERROR') {
						postMessage('session', 'ERROR');
					}
					setTimeout(() => {
						props.history.push({
							pathname: `/consent-status`,
							state: {
								status: 'Consent denied',
								statusCode: 403,
							},
						});
					}, 500);
				}
			});
	};

	const handleAcceptFiuMultiple = async () => {
		const consents = consentsContext?.selectedConsents;
		if (consents && consents.length > 0) {
			if (
				areAllArraysEmptyForConsents(
					state.consentSelectedAccounts,
					selectedConsentsState
				)
			) {
				toast.error('Select at least one account for a selected consent!');
				return;
			} else {
				let continueApprove = true;
				const consentAccounts = [];

				for (let k = 0; k < consents?.length; k++) {
					let requiredAccountsSelected = [];
					const requiredFI = consents[k].fiTypes;
					const selectedAccounts =
						state.consentSelectedAccounts[consents[k].ConsentHandle];
					let fips = selectedAccounts
						.filter((el) => requiredFI.includes(el?.FIType))
						.map((el) => el?.fipId);
					fips = [...new Set(fips)];
					for (let j = 0; j < fips?.length; j++) {
						let withFip = {
							FIP: {
								id: fips[j],
							},
							Accounts: [],
						};
						for (let i = 0; i < selectedAccounts?.length; i++) {
							if (requiredFI.includes(selectedAccounts[i]?.FIType)) {
								if (fips[j] === selectedAccounts[i]?.fipId) {
									let single = {
										linkRefNumber: selectedAccounts[i]?.linkRefNumber,
										accType: selectedAccounts[i]?.accType,
										accRefNumber: selectedAccounts[i]?.accRefNumber,
										maskedAccNumber: selectedAccounts[i]?.maskedAccNumber,
										FIType: selectedAccounts[i]?.FIType,
										fipId: selectedAccounts[i]?.fipId,
										fipName: selectedAccounts[i]?.fipName,
									};
									withFip.Accounts.push(single);
								}
							}
						}
						if (withFip.Accounts.length > 0) {
							requiredAccountsSelected.push(withFip);
						}
					}
					if (requiredAccountsSelected.length === 0) {
						toast.error('No relevant account selected/added');
						continueApprove = false;
					} else {
						consentAccounts.push({
							ConsentHandle: consents[k].ConsentHandle,
							fiuId: consents[k].FIU.id,
							Accounts: requiredAccountsSelected,
						});
					}
				}
				if (continueApprove) {
					setState({ isDisable: true, disableType: 'ACCEPT' });
					let isLast = false;
					for (let k = 0; k < consentAccounts?.length; k++) {
						if (k === consentAccounts?.length - 1) {
							isLast = true;
						}
						await consentApproveRequest(
							consentAccounts[k].Accounts,
							'ACCEPT',
							consentAccounts[k].ConsentHandle,
							consentAccounts[k].fiuId,
							isLast
						);
					}
				}
			}
		} else {
      if(global?.institutionType === "LSP"){
        setOpenDrawer(true)
      }else{
        toast.error("No consent selected!")
      }
			return;
		}
	};


	const handleDeny = async () => {
		setState({ ...state, isDisable: true });
		let isLast =
			consentsAll.length - consentsAccepted.length - consentsDenied.length === 1
				? true
				: false;
		const consents = consentsContext.consentList;
			for (let k = 0; k < consents.length; k++) {
				if (k === consents.length - 1) {
					isLast = true;
				}
				await consentApproveRequest(
					[],
					'DENY',
					consents[k].ConsentHandle,
					consents[k].FIU.id,
					isLast
				);
			}
			consentsContext.updateConsentList([]);
	};

	const updateAccounts = (el, data) => {
		if (data.includes(el)) {
			const index = data.indexOf(el);
			data.splice(index, 1);
		} else {
			data.push(el);
		}
		return data;
	};

	const selectUnselectAcc = async (el, consentHandle = null) => {
		let data = updateAccounts(el, [...state.selectedAccounts]);
		if (consentHandle) {
			const consentData = updateAccounts(el, [
				...state.consentSelectedAccounts[consentHandle],
			]);
			const accounts = state.consentSelectedAccounts;
			accounts[consentHandle] = consentData;
			setState({
				...state,
				selectedAccounts: data,
				consentSelectedAccounts: accounts,
			});
			return;
		}
		setState({ ...state, selectedAccounts: data });
	};

	//Floating Button logic
	const [unviewedConsents, setUnviewedConsents] = useState(
		consentsAll.length - 1
	);
	const consentListRef = useRef();

	useEffect(() => {
		const lastChildren = [
			...document.getElementsByClassName('lastChildCard'),
		].slice(1);
		const observer = new IntersectionObserver((entries) => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					observer.unobserve(entry.target);
					setUnviewedConsents((prev) => prev - 1);
				}
			}
		});
		for (const child of lastChildren) {
			observer.observe(child);
		}

		return () => {};
	}, []);

	const handleMoreConsentsClick = () => {
		const consentListElement = consentListRef.current;
		if (!consentListElement) return;

		const nextConsentIndex = consentsAll.length - unviewedConsents;
		const nextConsentElement = consentListElement.children[nextConsentIndex];
		nextConsentElement.scrollIntoView({ behavior: 'smooth' });
		nextConsentElement.focus();
	};
  const toggleSelectAll = () => {
		if (selectedConsentsState?.length < consentsAll.length) {
			setSelectedConsentsState(
				consentsAll.map((a) => {
					return a?.ConsentHandle;
				})
			);
      consentsContext.updateSelectedConsentList(
				consentsAll.map((a) => a?.ConsentHandle)
			);
			setSelectAll(true);
		} else {
      consentsContext.updateSelectedConsentList([]);
			setSelectedConsentsState([]);
			setSelectAll(false);
		}
	};


	return (
		<Box
			sx={{
				fontFamily: props?.customDetails?.fontFamily,
				display: 'flex',
				justifyContent: { xs: 'start', md: 'center' },
				flexDirection: {xs: 'column', md: 'column' },
				minHeight: { xs: 'auto', md: 'auto' },
				alignItems: 'start',
        m:`${!mobileScreen? "0 3rem":"none"}`
			}}
		>
			<RequestedConsents
				display={'flex'}
				customDetails={props.customDetails}
				consentDetailsList={state?.consentDetailsList}
				selectedConsentsState={selectedConsentsState}
				handleIsActive={selectUnselectConsents}
        selectAll={selectAll}
        toggleSelectAll={toggleSelectAll}
				{...props}
			/>
				<div
					ref={consentListRef}
					style={{
						display: 'flex',
						flexDirection: 'column',
						// gap: '1rem',
						width: '100%',
					}}
				>
					{consentsAll.map((consent, idx) => (
						<ConsentCardFull
							key={idx}
							customDetails={props.customDetails}
							consentDetails={consent}
							id={idx + 1}
							selectedConsentsState={selectedConsentsState}
							handleIsActive={selectUnselectConsents}
							isMultiConsent={consentsAll?.length > 1}
							isLast={idx === consentsAll.length - 1}
						>
							<LinkedAccountsSection
								handleSelect={selectUnselectAcc}
								superState={state}
								selectedConsentsState={selectedConsentsState}
								linkedAccounts={state.linkedAccounts}
								customDetails={props.customDetails}
								consentDetails={
									state?.consentDetailsList || global.selectedConsent
								}
								consentHandle={consent?.ConsentHandle}
								selectedAccounts={state.selectedAccounts}
								handleAccept={handleAcceptFiuMultiple}
								handleDeny={handleDeny}
								consent={consent}
								{...props}
							/>
						</ConsentCardFull>
					))}
					<FloatingButton
						numberOfUnviewedConsents={unviewedConsents}
						handleClick={handleMoreConsentsClick}
					/>
					<div>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								width: '100%',
							}}
						>
							<Box
								sx={{
									bottom: mobileScreen ? '0rem' : '1.875rem',
									right: mobileScreen ? '0rem' : '0rem',
									backgroundColor: mobileScreen ? 'white' : 'none',
									gap: mobileScreen ? '1rem' : '1.25rem',
									width: mobileScreen ? '100vw' : '20%',
									flexWrap: 'wrap',
									...linkedAccountsSectionStyles.buttonGroup,
								}}
							>
								<Button
									variant="outlined"
									fullWidth={mobileScreen}
									color="error"
									onClick={() => setDeny(true)}
									sx={{ flex: 1, background: theme.palette.primary.white }}
								>
									Deny
								</Button>
								<Button
									variant="contained"
									fullWidth={mobileScreen}
									disabled={unviewedConsents > 0}
									onClick={() => handleAcceptFiuMultiple()}
									sx={{
										backgroundColor: props.customDetails.primaryColor,
										flex: 1,
									}}
								>
									Accept
								</Button>
								{mobileScreen ? <WhyShare {...props} /> : null}
							</Box>
						</Box>
					</div>
				</div>
			<DialogBox
				open={deny}
				handleClose={handleClose}
				title="Deny Consent"
				subTitle="Are you sure you want to deny this consent."
				boxPadding="1.5rem"
				subTitleMaxWidth="85%"
			>
				<DenyConsent
					handleCancel={() => setDeny(false)}
					{...props}
					handleDeny={handleDeny}
				/>
			</DialogBox>
      <Drawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        medWidth= {0}
        closeBtnTop={"1rem"}
        customDetails={props?.customDetails}
      >
        <FiuList 
          selectUnselectConsents={selectUnselectConsents} 
          handleAccept={handleAcceptFiuMultiple}
          selectedConsentsState={selectedConsentsState}
          linkedAccountsSelected={state.consentSelectedAccounts}
          />
      </Drawer>
      {!mobileScreen && 
          <Box sx={{  m:"1rem auto"}}>
            <WhyShare {...props} />
          </Box>}
		</Box>
	);
}

export default PaymentConsent;
